import Vue from 'vue';
import Vuex from 'vuex';
import { version } from '/package.json';

import user from './modules/user';
import style from './modules/style';
import theme from './modules/theme';
import loading from './modules/loading';
import confirm from './modules/confirm';
import renderDialog from './modules/renderDialog';
import entriesTypeMenu from './modules/entriesTypeMenu';
import sorting from './modules/sorting';
import { getAccessToken } from '@/helper/localStorage';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: getAccessToken(),
    packageVersion: version || '0',
  },
  mutations: {
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
    },
  },
  actions: {},
  getters: {
    isLogin: (state) => !!state.accessToken,
    appVersion: (state) => state.packageVersion,
  },
  modules: {
    style,
    user,
    theme,
    loading,
    confirm,
    renderDialog,
    entriesTypeMenu,
    sorting,
  },
});
