export const ligthColor = {
  primary: '#1d4a85', // orange
  secondary: '#252634', // grey
  accent: '#3CC2E0', // cyan
  error: '#FF606B', // red,
  info: '#2196F3',
  success: '#40c57d', // gren
  warning: '#FFC107',
  background: '#f7f7fb',
};

export const darkColor = {
  primary: '#1d4a85', // orange
  secondary: '#1e1f28', // grey
  accent: '#3CC2E0', // cyan
  error: '#FF606B', // red,
  info: '#2196F3',
  success: '#40c57d', // gren
  warning: '#FFC107',
  background: '#161515',
  //-----------------
  // only work in dark
  //-----------------
  label: '#acdbff',
  vcard: '#252525',
  datatable: '#2d2d2d',
  paginationnav: '#252525',
  paginationitem: '#2d2d2d',
  //-----------------
};
