<template>
  <v-list-item
    :to="getTo"
    @click="itemClickHandler"
    class="ma-0 align-center"
    style="width: 100%"
  >
    <!-- Menu Icon -->
    <v-list-item-icon v-if="item.icon" class="ml-3">
      <v-icon class="">{{ item.icon }}</v-icon>
    </v-list-item-icon>

    <!-- Menu Item -->
    <v-list-item-content>
      <v-list-item-title class="text-subtitle-2" color="red">
        {{ item.title }}
      </v-list-item-title>
    </v-list-item-content>

    <v-btn
      v-if="buttonLoading || hasChild()"
      icon
      :loading="buttonLoading"
      color="white"
      @click.prevent="expandBtnClickHandler"
    >
      <v-icon>{{ expandIcon }}</v-icon>
    </v-btn>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    to: {},
  },
  data() {
    return {
      isExpand: false,
    };
  },
  computed: {
    buttonLoading() {
      const { expandLoading } = this.item;
      return expandLoading;
    },
    isOnClickExpand() {
      const { onClickExpand } = this.item;
      return onClickExpand;
    },
    getTo() {
      if (this.isOnClickExpand) return;
      return this.to;
    },
    itemClickHandler() {
      if (this.isOnClickExpand) return this.expand;
      return () => {};
    },
    expandBtnClickHandler() {
      if (!this.isOnClickExpand) return this.expand;
      return () => {};
    },
    expandIcon() {
      if (this.isExpand) return 'mdi-chevron-up';
      return 'mdi-chevron-down';
    },
    currentRoute() {
      return this.$route;
    },
  },
  methods: {
    hasChild() {
      const hasChild = this.item.children && this.item.children.length > 0;
      return hasChild;
    },
    chkMustExpanded(currentRoute) {
      const hasChild = this.hasChild();
      if (!hasChild) return;
      const { children } = this.item;

      const currentRouteParams = JSON.stringify(currentRoute.params);
      const currentRouteQuery = JSON.stringify(currentRoute.query);
      const currentRouteName = currentRoute.name;

      const routeMustExpand = children.find((router) => {
        if (!router) return;
        if (typeof router == 'string') return router == currentRouteName;
        // child router
        const { route } = router;

        // get the same keys
        const routeParams = Object.keys(currentRoute.params).reduce((p, k) => {
          if (route.params[k]) return { ...p, [k]: route.params[k] };
          return p;
        }, {});

        const params = JSON.stringify(routeParams);
        const query = JSON.stringify(route.query);
        const name = route.name;

        const isSameParams = currentRouteParams == params;
        const isSameQuery = currentRouteQuery == query;
        const isSameName = currentRouteName == name;

        return isSameParams && isSameQuery && isSameName;
      });

      const isMustExpanded = routeMustExpand && !this.isExpand;
      // const isMustHide = !isInRoute && this.isExpand;
      if (isMustExpanded /*|| isMustHide*/) this.expand();
    },
    expand() {
      this.isExpand = !this.isExpand;
      this.$emit('expanding', this.isExpand);
    },
  },
  mounted() {},
  watch: {
    currentRoute(route) {
      this.chkMustExpanded(route);
    },
  },
};
</script>
