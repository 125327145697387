<template>
  <div>
    <v-dialog v-model="dialogOpen" max-width="450" :persistent="isConfirm">
      <v-card>
        <v-card-title class="text-h5 darken-3 white--text" :class="color">
          {{ title }}
        </v-card-title>
        <v-progress-linear
          :active="isConfirm"
          indeterminate
          color="red darken-10"
        />

        <v-card-text>
          <v-toolbar-title class="font-weight-bold mt-5"
            >{{ message }}
          </v-toolbar-title>
          <v-toolbar-title v-if="subMessage" class="subtitle-2"
            >{{ subMessage }}
          </v-toolbar-title>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :color="getYesColor"
            text
            @click="confirm"
            outlined
            :loading="isConfirm"
          >
            نعم
          </v-btn>
          <v-btn
            :color="getNoColor"
            text
            @click="closeConfirm"
            outlined
            :disabled="isConfirm"
          >
            كلا
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    dialogOpen: {
      // getter
      get() {
        return this.isOpenConfirm;
      },
      // setter
      set() {
        this.closeConfirm();
      },
    },
    ...mapState({
      isOpenConfirm: (state) => state.confirm.isOpenConfirm,
      title: (state) => state.confirm.title,
      message: (state) => state.confirm.message,
      subMessage: (state) => state.confirm.subMessage,
      color: (state) => state.confirm.color,
      isConfirm: (state) => state.confirm.isConfirm,
      isDark: (state) => state.theme.dark,
    }),
    getNoColor() {
      if (this.isDark) return 'white';
      return 'black';
    },
    getYesColor() {
      if (this.isDark) return this.color;
      return this.color + ' darken-1';
    },
  },
  methods: {
    ...mapActions('confirm', ['closeConfirm', 'confirm']),
  },
};
</script>
