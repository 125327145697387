import { saveConfigTheme } from '@/helper/localStorage';
import { darkColor, ligthColor } from '@/libs/theme/themeColors';
import { getThemeConfig } from '@/libs/theme/utls';

export default {
  namespaced: true,
  state: {
    ...getThemeConfig(),
  },
  getters: {
    primary: (state) => state.colors.secondary,
    secondary: (state) => state.colors.secondary,
    background: (state) => state.colors.background,
  },
  mutations: {
    UPDATE_COLORS(state, isDark) {
      state.dark = isDark;
      if (isDark) state.colors = darkColor;
      else state.colors = ligthColor;
      saveConfigTheme(state);
    },
  },
  actions: {},
};
