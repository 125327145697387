<template>
  <v-navigation-drawer
    v-model="$store.state.theme.drawer"
    bottom
    floating
    app
    right
    :color="secondary"
    class="main-navigation-drawer"
  >
    <!-- CMS LOGO -->
    <AppLogo />
    <!-- Menu -->
    <NavigationItems :items="items" />

    <v-spacer />
    <!-- appVersion -->
    <AppVersion />
  </v-navigation-drawer>
</template>
<script>
import navigationItems, {
  buildEntriesChildsTypeMenu,
  setEntriesNavigationLoading,
} from '@/config/navigation';

import { mapActions, mapGetters, mapState } from 'vuex';
import NavigationItems from './NavigationItems.vue';
import AppVersion from './components/AppVersion.vue';
import AppLogo from './components/AppLogo.vue';

export default {
  data() {
    return {
      items: navigationItems,
    };
  },
  computed: {
    ...mapGetters('style', ['secondary']),
    ...mapState('entriesTypeMenu', ['types', 'loading']),
  },
  components: { NavigationItems, AppVersion, AppLogo },
  watch: {
    types(val) {
      buildEntriesChildsTypeMenu(val);
    },
    loading(val) {
      setEntriesNavigationLoading(val);
    },
  },
  methods: {
    initEntriesChilds() {
      const loading = this.types.length == 0 || this.loading;
      setEntriesNavigationLoading(loading);
      buildEntriesChildsTypeMenu(this.types);
    },
  },
  created() {
    this.initEntriesChilds();
  },
};
</script>

<style lang="scss">
.main-navigation-drawer {
  overflow: visible !important;
  & > div {
    overflow: visible !important;
    display: flex;
    flex-direction: column;
  }
}
</style>
