import store from '@/store';
import apiHelper from '../http/apiHelper';
import axiosIns from '../http/http';
import {
  clearSorageData,
  getAccessToken,
  getUserData,
  saveUserData,
} from '@/helper/localStorage';
import router from '@/router';

const getAxiosInsForAuth = ({
  url,
  data,
  params,
  method = 'get',
  schema,
  token,
}) =>
  axiosIns({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
    baseURL: apiHelper.baseURL + schema,
    url,
    method,
    data,
    params,
  });

const loginWithToken = (usernameOrEmail, password) => {
  const url = '/token';
  const method = 'POST';
  const schema = apiHelper.tokenSchema;
  const formData = new URLSearchParams();
  formData.append('grant_type', 'password');
  formData.append('username', usernameOrEmail);
  formData.append('password', password);
  formData.append('client_id', apiHelper.clientID);
  return getAxiosInsForAuth({ schema, url, method, data: formData });
};

export function authLogin({
  usernameOrEmail,
  password,
  onStart,
  onLogin,
  onFail,
  onFinsh,
}) {
  onStart();
  loginWithToken(usernameOrEmail, password)
    .then(async (res) => {
      if (res.status != 200) return onFail(Error(`Request (${res.status})`));
      // login but is not authenticated
      const accessToken = res.data.access_token;
      // in callback we checked is authenticated
      await onLogin(accessToken);
    })
    .catch(onFail)
    .finally(onFinsh);
}

export async function loadAndSaveCurrentUserInfo(token) {
  const currentUserData = await getCurrentUserAndSaveUserIfIsAuth(token);
  await loadNavigationMenuTypes();
  return currentUserData;
}

async function loadNavigationMenuTypes() {
  await store.dispatch('entriesTypeMenu/loadTypes');
}

export async function getCurrentUserAndSaveUserIfIsAuth(token) {
  const accessToken = token || getAccessToken();
  if (!accessToken || accessToken.length == 0) return;
  const currentUserData = await getCurrentUser(accessToken);
  saveUserIfIsAuth(currentUserData);
  return currentUserData;
}

function saveUserIfIsAuth(currentUserData) {
  const { isAuthenticated } = currentUserData;
  if (!isAuthenticated) return;
  saveUserData(currentUserData);
}

export function commitUserData() {
  var currentUserData = getUserData();
  store.commit('user/SET_USER_DATA', currentUserData);
}

// export async function notifyUserChange() {
//   await getCurrentUserAndSaveUserIfIsAuth();
//   commitUserData();
// }

export async function getCurrentUser(token) {
  const schema = '/api/abp';
  const url = '/application-configuration';
  const result = await getAxiosInsForAuth({ schema, url, token });

  const { currentUser, auth } = result.data;
  const { grantedPolicies } = auth || {};
  const { isAuthenticated } = currentUser;

  const grantedDynamicPolicies = await getGrantedDynamicPolicies(
    isAuthenticated,
    token,
  );

  return {
    jwt: token,
    user: currentUser,
    grantedPolicies,
    grantedDynamicPolicies,
    isAuthenticated,
  };
}

export async function getGrantedDynamicPolicies(isAuthenticated, token) {
  if (!isAuthenticated) return {};
  const schema = '/api/app';
  const url = '/dynamic-permission/granted';
  const result = await getAxiosInsForAuth({ schema, url, token });

  const { data } = result;
  if (!data) return {};

  const grantedDynamicPolicies = data.reduce(
    (p, v) => ({ ...p, [v]: true }),
    {},
  );
  return grantedDynamicPolicies;
}

export function doLogout() {
  clearSorageData();
  store.commit('user/SET_USER_DATA', null);
  store.commit('SET_ACCESS_TOKEN', '');
  router.push('/login').catch(() => {});
}
