export default [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      title: 'تسجيل الدخول',
    },
  },
];
