<template>
  <v-app :style="colors">
    <!-- Navigation -->
    <navigation-bar v-if="isAuth" />

    <v-main>
      <Snackbar />
      <!-- AppBar  -->
      <app-bar v-if="isAuth" />

      <!-- Body  -->
      <div :class="isAuth ? 'wrapper' : ''">
        <!-- <transition name="fade" mode="out-in"> -->
        <transition name="scale" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-main>
    <ConfirmDialog />
    <!-- <HTMLRenderDialog /> -->
  </v-app>
</template>
<script>
import { getColorAsCSSVars } from '@/libs/theme/utls';
import AppBar from './AppBar.vue';
import NavigationBar from './NavigationBar.vue';
import Snackbar from '@/components/Snackbar.vue';

import { mapGetters } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { loadAndSaveCurrentUserInfo } from '@/libs/auth/authHelper';
// import HTMLRenderDialog from '@/components/HTMLRenderDialog.vue';

export default {
  computed: {
    ...mapGetters({
      background: 'style/background',
      isLogin: 'isLogin',
      isAuthenticated: 'user/isAuthenticated',
    }),
    isAuth() {
      const isAuth = this.isLogin && this.isAuthenticated;
      return isAuth;
    },
    colors() {
      return getColorAsCSSVars();
    },
  },
  components: {
    AppBar,
    NavigationBar,
    Snackbar,
    ConfirmDialog,
    // HTMLRenderDialog,
  },
  async created() {
    await loadAndSaveCurrentUserInfo();
  },
};
</script>

<style>
body,
.v-application,
* {
  font-family: 'Cairo', sans-serif !important;
  letter-spacing: 0 !important;
  /* transition: all 0.2s ease-in !important; */
}

.v-card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.shadow {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.gradient {
  background-image: linear-gradient(
    -140deg,
    var(--secondary-color) 17%,
    var(--primary-color) 67%
  ) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.08s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.v-main__wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 0;
  }

  > .wrapper {
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 12px;
    border-radius: 10px;

    & > div {
      flex: 1;
    }
  }
}

.v-list .v-list-item--active {
  color: white !important;
  background-color: var(--primary-color) !important;
  max-width: 100% !important;
}

.theme--dark {
  &.v-data-table {
    background-color: var(--datatable-color) !important;
  }

  &.v-card {
    background-color: var(--vcard-color);
  }

  &.v-input--is-focused {
  }

  &.v-pagination {
    [class^='v-pagination']:not(.primary) {
      background-color: var(--paginationnav-color) !important;
    }

    .v-pagination__item:not(.primary) {
      background-color: var(--paginationitem-color) !important;
    }
  }
}

:not(.theme--dark) {
}
</style>
