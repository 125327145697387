import axios from 'axios';
import store from '@/store';
import apiHelper from './apiHelper';
import jwt from '@/libs/auth/jwt/jwt';
import { showSnackbarSuccessMessage } from '@/utls/snackbar';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiHelper.baseURL + apiHelper.apiSchema,
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
});

// --------------------------------------------------
// Each Request
// --------------------------------------------------
//
axiosIns.interceptors.request.use(onEachRequest, onEachRequestRejected);

function onEachRequest(request) {
  setAccessTokenInHeader(request);
  setDefaultLanguagegInHeader(request);
  return request;
}

function onEachRequestRejected(error) {
  return Promise.reject(error);
}

function setAccessTokenInHeader(request) {
  // Get token from localStorage
  const accessToken = jwt.getToken();
  // If token is present add it to request's Authorization Header
  if (accessToken) {
    request.headers.Authorization = `${jwt.jwtConfig.tokenType} ${accessToken}`;
  } else {
    request.headers.Authorization = null;
  }
}

function setDefaultLanguagegInHeader(request) {
  request.headers['accept-language'] = 'ar';
}

// --------------------------------------------------
// Each Response
// --------------------------------------------------
//
axiosIns.interceptors.response.use(onEachResponse, onEachResponseRejected);

function onEachResponse(response) {
  // const {
  //   status,
  //   config: { method, url },
  // } = response;

  // if (method != 'get' && url != '/token' && status == 200) {
  //   showSnackbarSuccessMessage('تم الحفظ بنجاح');
  // }
  return response;
}

function onEachResponseRejected(error) {
  store.commit('loading/SET_SHOW', false);
  return Promise.reject(error);
}

export default axiosIns;
