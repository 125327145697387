export default {
  namespaced: true,
  state: {
    show: false,
    dialog: false,
  },
  mutations: {
    SET_SHOW(state, val) {
      state.show = val;
    },
    SET_DIALOG_LOADING(state, val) {
      state.dialog = val;
    },
  },
};
