import http from '../libs/http/http';
const moment = require('moment');

export const formatDate = (date, showTime = true) => {
  let formate = 'YYYY/MM/DD';
  if (showTime) {
    formate = 'YYYY/MM/DD hh:mm A';
  }
  const ret = moment(date).format(formate);
  return ret;
};

export const hideFrom = (roles) => {
  return true;
};

export const float = (x) => {
  return typeof x == 'string' ? parseFloat(x.replace(',', '')) : x;
};

export const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('files', file);
    http
      .post('/upload', formData, {
        onUploadProgress: (event) => {
          const statusProgress = Math.round((event.loaded * 100) / event.total);
          console.log({ statusProgress });
          // store.state.uploadProgress = statusProgress;
        },
      })
      .then((res) => {
        resolve(res.data[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFileNameWithoutExtension = (fileName) => {
  return fileName.replace(/\.[^/.]+$/, '');
};

export function getBlobTypeFromExtension(extension) {
  const extensionToMIME = {
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.gif': 'image/gif',
    '.bmp': 'image/bmp',
    '.webp': 'image/webp',
  };
  return extensionToMIME[extension.toLowerCase()];
}

export default {
  formatDate,
  hideFrom,
  float,
  uploadFile,
  getFileNameWithoutExtension,
  getBlobTypeFromExtension,
};
