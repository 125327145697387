<template>
  <v-toolbar flat max-width="100%" elevation="1">
    <v-toolbar-title>
      <v-btn
        color="primary"
        text
        @click="$store.commit('theme/TOGGLE_DRAWER', !$store.state.theme.drawer)"
      >
        <v-icon size="30">mdi-notification-clear-all</v-icon>
      </v-btn>
      <span
        v-if="$route.meta.showTitle !== false || $store.state.theme.drawer == false"
      >
        {{ $route.meta.title }}
      </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <theme-type></theme-type>
    <user />
  </v-toolbar>
</template>
<script>
import ThemeType from './ThemeType.vue';
import User from './User.vue';
export default {
  components: {
    ThemeType,
    User,
  },
};
</script>
