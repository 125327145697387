export default {
  namespaced: true,
  state: {
    title: '',
    content: '',
    isShow: false,
  },
  mutations: {
    SHOW(state, { isShow, title, content }) {
      state.title = title || '';
      state.content = content || '';
      state.isShow = isShow;
    },
  },
  actions: {
    render({ commit }, { isShow = true, title, content }) {
      commit('SHOW', { isShow, title, content });
    },
  },
};
