import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import { beforeEnterCheckPermission } from './utils/routePermissions';

export default [
  {
    name: 'content-status',
    path: '/contentstatus',
    component: () => import('@/views/contentStatus/ContentStatus.vue'),
    meta: {
      title: 'حالات المحتوى',
    },
    beforeEnter(to, from, next) {
      const { has, redirectTo } = beforeEnterCheckPermission(
        appPermissions.contentStatus,
      );
      if (has) return next();
      else return next(redirectTo);
    },
  },
];
