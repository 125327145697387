import store from '@/store';

const grantedPolicies = {
  get policies() {
    return store.getters['user/grantedPolicies'];
  },
  isGranted(name) {
    const keys = Object.keys(this.policies);
    const policieName = keys.find((x) => x.toLowerCase() == name.toLowerCase());
    const isGranted = this.policies[policieName] || false;
    return isGranted;
  },
};

function canRead(permissionPerfix, name) {
  const fullName = permissionPerfix + '.' + name;
  const isGranted = grantedPolicies.isGranted(fullName);
  return isGranted;
}
function canCreate(permissionPerfix, name) {
  const fullName = permissionPerfix + '.' + name + '.' + 'Create';
  const isGranted = grantedPolicies.isGranted(fullName);
  return isGranted;
}
function canUpdate(permissionPerfix, name) {
  const fullName = permissionPerfix + '.' + name + '.' + 'Update';
  const isGranted = grantedPolicies.isGranted(fullName);
  return isGranted;
}
function canDelete(permissionPerfix, name) {
  const fullName = permissionPerfix + '.' + name + '.' + 'Delete';
  const isGranted = grantedPolicies.isGranted(fullName);
  return isGranted;
}
function canManagePermissions(permissionPerfix, name) {
  const fullName = permissionPerfix + '.' + name + '.' + 'ManagePermissions';
  const isGranted = grantedPolicies.isGranted(fullName);
  return isGranted;
}

const getPermission = (permissionPerfix, name) => {
  const isCanRead = canRead(permissionPerfix, name);
  const isCanCreate = canCreate(permissionPerfix, name);
  const isCanUpdate = canUpdate(permissionPerfix, name);
  const isCanDelete = canDelete(permissionPerfix, name);
  const isCanManagePermissions = canManagePermissions(permissionPerfix, name);
  return {
    isCanRead,
    isCanCreate,
    isCanUpdate,
    isCanDelete,
    isCanManagePermissions,
  };
};

export const alMurabaCMSPermissions = (name) => {
  const permissionPerfix = 'AlMurabaCMS';
  if (!name) throw Error(permissionPerfix + 'Permissions name undefiend');
  return getPermission(permissionPerfix, name);
};

export const identityPermissions = (name) => {
  const permissionPerfix = 'AbpIdentity';
  if (!name) throw Error(permissionPerfix + 'Permissions name undefiend');
  return getPermission(permissionPerfix, name);
};

export const dynamicPermissions = (name) => {
  const permissionPerfix = 'AlMurabaCMSDynamicPermissions';
  if (!name) throw Error(permissionPerfix + 'Permissions name undefiend');
  return getPermission(permissionPerfix, name);
};

export const permissions = (
  name,
  { isIdentity = false, isDynamic = false } = {},
) => {
  if (isIdentity) return identityPermissions(name);
  if (isDynamic) return dynamicPermissions(name);
  return alMurabaCMSPermissions(name);
};
