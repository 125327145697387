<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-large text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
          <label> {{ username }} </label>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-btn @click="goToSettings" text block class="justify-start">
            <v-icon>mdi-cog</v-icon>
            الأعدادات
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn @click="logout()" text block class="justify-start">
            <v-icon>mdi-logout</v-icon>
            تسجيل خروج
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { doAfterUserIdlingInactivity } from '@/helper/commonjs';
import { mapGetters } from 'vuex';
import { doLogout } from '@/libs/auth/authHelper';

export default {
  computed: {
    ...mapGetters('user', ['username']),
  },
  methods: {
    logout() {
      doLogout();
    },
    logoutOnIdling() {
      const numberOfSeconds = 60 * 5;
      doAfterUserIdlingInactivity(numberOfSeconds, this.logout);
    },
    goToSettings() {
      if (this.$route.name == 'settings') return;
      this.$router.push('/settings');
    },
  },
  created() {
    this.logoutOnIdling();
  },
};
</script>
