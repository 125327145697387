import { permissions } from '@/libs/auth/permissions/permissionUtls';

export const appPermissions = {
  navTypes: () => permissions('NavType'),
  navigations: () => permissions('Navigation'),
  pages: () => permissions('Page'),
  contentStatus: () => permissions('ContentStatus'),
  contentType: () => permissions('ContentType'),
  category: () => permissions('Category'),
  dynamicContent: () => permissions('DynamicContent'),
  entries: () => permissions('Entry'),
  users: () => permissions('Users', { isIdentity: true }),
  roles: () => permissions('Roles', { isIdentity: true }),
  upload: () => permissions('Upload'),
  contentTag: () => permissions('ContentTag'),
  notification: () => permissions('Notification'),
  audit: () => permissions('Audit'),
  dynamicContentType: (id) => permissions(`DynamicContentType.${id}`, { isDynamic: true }),
};
