import { isDarkTheme, saveDarkTheme } from '@/helper/localStorage';
import config from '../../config/config';

export default {
  namespaced: true,
  state: {
    isRTL: config.isRTL,
    dark: isDarkTheme(),
    drawer: true,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
  },
  mutations: {
    UPDATE_RTL(state, val) {
      state.isRTL = val;
    },
    UPDATE_DARK(state, val) {
      saveDarkTheme(val);
      state.dark = val;
    },
    TOGGLE_DRAWER(state, val) {
      if (typeof val == 'boolean') state.drawer = val;
      else state.drawer = !state.drawer;
    },
    UPDATE_SNACKBAR(state, val) {
      state.snackbar = val;
    },
    UPDATE_SNACKBAR_MESSAGE(state, { message, color = 'error' }) {
      state.snackbarMessage = message;
      state.snackbarColor = color;
      if (message) state.snackbar = true;
    },
    UPDATE_SNACKBAR_SHOW(state, isShow) {
      state.snackbar = isShow;
    },
  },
};
