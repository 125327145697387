import { ligthColor } from '@/libs/theme/themeColors';
import networkConfig from '@/libs/http/apiHelper';
import { getCMSInformation } from '@/helper/localStorage';

export default {
  projectName: 'تجربة',
  isRTL: true,
  dark: false,
  backType: 'gradient',
  image: null,
  appbar: 'light',
  drawer: 'dark',
  colors: { ...ligthColor },
  networkConfig,
  information: () => getCMSInformation(),
};

class Language {
  value = '';
  key = '';
  constructor(key, value) {
    this.key = key;
    this.value = value;
  }
}

export const languages = [
  { none: 'بدون' },
  { ar: 'عربي', default: 1 },
  { kr: 'كردي' },
  { en: 'انجليزي' },
];

export const selectedLanguage = Object.keys(
  languages.find((x) => x.default == 1),
)[0];

export const languagesKeysValue = () =>
  languages.reduce((p, c) => {
    const key = Object.keys(c)[0];
    const value = Object.values(c)[0];
    if (key == 'none') return p;
    return [...p, new Language(key, value)];
  }, []);

export const defaultLanguagesKeysValue = () =>
  languages
    .filter((x) => x.default == 1)
    .reduce((p, c) => {
      const key = Object.keys(c)[0];
      const value = Object.values(c)[0];
      if (c.default != 1) return p;
      return [...p, new Language(key, value)];
    }, []);

export const languagesKeys = () =>
  languages.reduce((p, c) => {
    const key = Object.keys(c)[0];
    if (key == 'none') return p;
    return { ...p, [`${key}`]: {} };
  }, {});

export const defaultLanguagesKeys = () =>
  languages.reduce((p, c) => {
    const key = Object.keys(c)[0];
    if (c.default != 1) return p;
    return { ...p, [`${key}`]: {} };
  }, {});

export const languagesBuildKeys = (name) =>
  languages.reduce((p, c) => {
    const key = Object.keys(c)[0];
    if (key == 'none') return p;
    return { ...p, [`${key}_${name}`]: '' };
  }, {});
