import Vue from 'vue';
import VueRouter from 'vue-router';
import { isUserLoggedIn } from '@/libs/auth/utils';
import { beforeEnterCheckPermission } from './routes/utils/routePermissions';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

import mainRoute from './routes/mainRoute';
import dashboardRoute from './routes/dashboardRoute';
import loginRoute from './routes/loginRoute';
import navTypeRoute from './routes/navTypeRoute';
import navigationRoute from './routes/navigationRoute';
import pageRoute from './routes/pageRoute';
import contentStatusRoute from './routes/contentStatusRoute';
import contentTypeRoute from './routes/contentTypeRoute';
import categoryRoute from './routes/categoryRoute';
import dynamicContentRoute from './routes/dynamicContentRoute';
import entriesRoute from './routes/entriesRoute';
import rolesManagementRoute from './routes/rolesManagementRoute';
import status404Route from './routes/status404Route';
import settingsRoute from './routes/settingsRoute';

Vue.use(VueRouter);

const routes = [
  ...mainRoute,
  ...dashboardRoute,
  ...loginRoute,
  ...navTypeRoute,
  // ...navigationRoute,
  ...pageRoute,
  ...contentStatusRoute,
  ...contentTypeRoute,
  ...categoryRoute,
  ...dynamicContentRoute,
  ...entriesRoute,
  ...rolesManagementRoute,
  ...status404Route,
  ...settingsRoute,
  {
    name: 'users',
    path: '/users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      title: 'المستخدمين',
    },
    beforeEnter(to, from, next) {
      const { has, redirectTo } = beforeEnterCheckPermission(
        appPermissions.users,
      );
      if (has) return next();
      else return next(redirectTo);
    },
  },
  { path: '*', redirect: '404' },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  if (isAccessRouteWithoutLogin({ toRouteName: to.name }))
    return redirectToLoginPage(next);
  else if (isAuthAndAccessLogin({ toRouteName: to.name }))
    return redirectToMainPage(next, { fromRouteName: from.name });
  return next();
});

function isAccessRouteWithoutLogin({ toRouteName }) {
  const isUnauth = !isUserLoggedIn();
  const isToOtherPages = toRouteName !== 'login';
  return isToOtherPages && isUnauth;
}

function redirectToLoginPage(next) {
  return next({ name: 'login' });
}

function isAuthAndAccessLogin({ toRouteName }) {
  const isAuth = isUserLoggedIn();
  const isToLoginPage = toRouteName === 'login';
  return isToLoginPage && isAuth;
}

function redirectToMainPage(next, { fromRouteName }) {
  return next({ name: fromRouteName || 'dashboard-home' });
}

export default router;
