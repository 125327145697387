<template>
  <div class="menu_container">
    <v-list nav class="menu" :style="getChildMenuStyle">
      <template v-for="item in items">
        <v-list-item
          v-if="isShowItem(item)"
          :key="item.title"
          :class="[getItemClass(item), getChildMenuItemClass(item)]"
          :style="[getItemStyle(item), getChildMenuItemStyle(item)]"
          dark
        >
          <!-- item  -->
          <NavigationItem
            :item="item"
            @expanding="expanding"
            :to="getItemRoute(item)"
          />

          <!-- item's children -->
          <v-expand-transition>
            <NavigationItems
              v-show="isShow && hasChild(item)"
              :isParent="true"
              :items="item.children"
            />
          </v-expand-transition>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import NavigationItem from './NavigationItem.vue';
export default {
  name: 'NavigationItems',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    isParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    getChildMenuStyle() {
      if (!this.isParent) return {};
      return {
        borderRight: '4px dashed #dddddd11',
        padding: '0px 10px',
        width: '95%',
        marginRight: '10px',
        marginBottom: '15px',
      };
    },
  },
  methods: {
    isShowItem({ show }) {
      if (!show) return true;
      return show && show();
    },
    hasChild(item) {
      return item.children && item.children.length > 0;
    },
    getItemRoute(item) {
      return typeof item.route == 'string' ? { name: item.route } : item.route;
    },
    getItemClass(item) {
      return 'pa-0 mx-0 my-2';
    },
    getChildMenuItemClass(item) {
      if (this.hasChild(item)) return 'flex-column align-start';
    },
    getItemStyle(item) {},
    getChildMenuItemStyle(item) {
      if (!this.hasChild(item)) return '';
      const elementStyle = { minHeight: 0 };
      if (!this.isShow) return elementStyle;
      const elementWithExpandedCardStyle = {
        ...elementStyle,
        backgroundColor: '#eeeeee09',
      };
      return elementWithExpandedCardStyle;
    },
    expanding(expand) {
      this.isShow = expand;
    },
  },
  components: { NavigationItem },
};
</script>

<style lang="scss" scoped>
@import '@/sass/mixin.scss';

.menu_container {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbars();
  .menu {
  }
}
</style>
